import React from 'react'
import Helmet from 'react-helmet'
import Offerings from '../Offerings'
import PropTypes from 'prop-types'

const SEFTMTFATPageTemplate = ({ meta, hero, offerings, banner3, ft_button }) => (
  <div>
    <Helmet>
      <title>{meta.meta_title}</title>
      <meta name='description' content={meta.meta_description} />
    </Helmet>

    <section className='hero is-primary is-bold channelmanager'>
      <div className=''>
        <div className='container'>
          <div className='section'>
            <h1>{hero.heading}</h1>
            <h2 className='has-text-weight-semibold'>{hero.description}</h2>
          </div>
        </div>
      </div>
    </section>

    <section className='section section--gradient'>
      <div className='container'>
        <div className=''>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='content'>
                <Offerings gridItems={offerings.blurbs} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div className='hp-banner3'>
      <div className='container'>
        <h2>{banner3.heading1}</h2>
        <div className='offer-checklist columns is-multiline'>
          {printChecklist(banner3.checklist)}
        </div>
        {(!!banner3.ft_button.url && !!banner3.ft_button.text) && (
          <div className='column trialelement'>
            <a
              className='button free-trial-btn2'
              href={banner3.ft_button.url}
            >
              {banner3.ft_button.text}
            </a>
          </div>
        )}
      </div>
    </div>
  </div>
)

function printChecklist (checklistToPrint) {
  checklistToPrint = checklistToPrint || []

  const listItems = checklistToPrint.map(function (item, i) {
    return (
      <div className='column' key={i}>
        <img alt='Vantagens do Channel Manager' src='/img/done.png' />
        {item}
      </div>
    )
  })

  return listItems
}

SEFTMTFATPageTemplate.propTypes = {
  meta: PropTypes.shape({
    meta_title: PropTypes.string,
    meta_description: PropTypes.string,
  }),
  hero: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
  }),
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  banner3: PropTypes.shape({
    heading1: PropTypes.string,
    checklist: PropTypes.array,
  }),
}

export default SEFTMTFATPageTemplate
