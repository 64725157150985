import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Offering = props => (
  <div className='columns is-multiline is-vcentered'>
    <div
      className={
        props.orientation === 'right'
          ? 'column is-6 offer-show-first'
          : 'column is-6'
      }
    >
      <p className='has-text-centered offer-image'>
        <img alt='offers image' src={props.image} style={{ maxWidth: props.imagewidth + 'px' }} />
      </p>
    </div>
    <div className='column is-6 offer-description'>
      <p className='heading'>{props.heading}</p>
      <p>{props.text}</p>
      <div className='offer-checklist columns is-multiline'>
        {printChecklist(props.checklist)}
      </div>
      {printURL(props)}

    </div>
  </div>
)

function printURL ({ link_url, link_label }) {
  if (!link_url || !link_label) { return '' } else {
    return (
      <p>
        <a className='view-more' href={link_url}>
          {link_label} >
        </a>
      </p>
    )
  }
}

function printChecklist (checklistToPrint) {
  checklistToPrint = checklistToPrint || []

  const listItems = checklistToPrint.map(function (item, i) {
    return (
      <div className='column' key={i}>
        <img alt='Vantagens da TalkGuest' src='/img/done.png' />
        {item}
      </div>
    )
  })

  return listItems
}

const Offerings = ({ gridItems }) => (
  <div className='columns is-multiline'>
    {gridItems.map(item => (
      <div key={item.image} className='column is-12'>
        <section className='section'>
          <Offering
            heading={item.heading}
            image={item.image}
            imagewidth={item.imagewidth}
            orientation={item.orientation}
            text={item.text}
            link_url={item.link_url}
            link_label={item.link_label}
            checklist={item.checklist}
          />
        </section>
      </div>
    ))}
  </div>
)

Offerings.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      imagewidth: PropTypes.number,
      heading: PropTypes.string,
      text: PropTypes.string,
      orientation: PropTypes.string,
      link_url: PropTypes.string,
      link_label: PropTypes.string,
      checklist: PropTypes.array,
    }),
  ),
}

export default Offerings
